.App {
  text-align: center;
}

body{
  background-image: url('/src/assets/bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  height: 100vh;
}
